<template>
    <main>
        
        <div class="hero"  v-if="formImagesItem" :style="{ backgroundImage: `linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url(${(formImagesItem.image!==null?formImagesItem.image:formImagesItemDefault.image)})` }">
           <div v-if="formImagesItem" class="container container--xs text-center">
                <h1>{{formImagesItem.title}}</h1>
                <p>{{formImagesItem.subtitle}}</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Tiendas registradas</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Tiendas afiliadas</h2>
                <h3 class="subtitle-counter">Tiendas afiliadas <span>{{ totalShop.count_total_shop   }}</span></h3>
                <div class="py-lg">
                    <p>Descripción general, texto que llame a la acción para inscribirse y encontrarse listada con las otras tiendas del Club, texto a compartir y/o aprobar por el cliente.</p>    
                </div>
                <!-- jkjjkjkjjkjkjjkjk -->
                
                <form action="" class="form container container--xs" @submit.prevent="onSubmit">
                    <div class="form__control">
                        <label class="form__label form__label--required form__label--error">Selecionar país:</label>
                        
                        <select  class="form__field form__field--select" v-model="form.selectCountry" @change="onChangeCountry($event)">
                            <option v-if="oneCountry" :value="0">Seleccione pais</option>
                            <option v-for="(ubigeo, key) in dataUbigeoCountry" :key="key" :value="ubigeo.id">{{ ubigeo.name }}</option>
                            
                        </select>
                       
                        
                        <!-- <p class="form__note">No seleccionable mientrás sólo contenga una sola alternativa</p> -->
                    </div>

                    <div class="form__control">
                        <label class="form__label form__label--required form__label--error">Selecionar departamento:</label>
                        
                        <select  class="form__field form__field--select" @change="onChangeDepartment($event)">
                            <option :value="0">Seleccione departamento</option>
                            <option v-for="(ubigeo, key) in dataUbigeoDepartment" :key="key" :value="ubigeo.id">{{ ubigeo.name }}</option>
                        </select>
                        
                        <!-- <p class="form__note">Muestra de Alerta</p> -->
                    </div>
                    

                    <!-- <button class="cta mt-md"><span class="icon-search"></span> &nbsp; Buscar</button> -->

                </form>

                <div class="mt-lg">                    
                    <p class="text-skyblue" v-if="searching">Se encontraron {{ dataShop.length }} resultados de {{ totalShop.count_total_shop }} Tiendas registradas</p>
                    <div class="table-normal">
                        <table class="table-normal__content">
                            <tr>
                                <!-- <th>Razón social</th> -->
                                <th>Nombre comercial</th>
                                <th>Dirección</th>
                                <th>Distrito</th>
                                <!-- <th>Teléfono</th>
                                <th>E-mail</th> -->
                            </tr>
                            <tr v-for="(shop, key) in dataShop" :key="key">
                                <!-- <td>{{ shop.business_name }}</td> -->
                                <td>{{ shop.tradename }}</td>
                                <td>{{ shop.address }}</td>
                                <td>{{ shop.ubigeo.name }}</td>
                                <!-- <td>{{ shop.phone_movil }}</td>
                                <td>{{ shop.email }}</td> -->
                            </tr>
                            
                        </table>
                    </div>
                    
                </div>

                <!-- hahshajjasjkkjasj -->
            </div>
        </section>
    </main>
</template>

<script>
import { ref, reactive } from "vue";
import API from "@/api";
import router from "@/router"

export default {
    name: "AffiliateStores",
    setup(){        
        const searching = ref(false);
        const dataUbigeo = ref({});
        const dataUbigeoCountry = ref([]);
        const dataUbigeoDepartment = ref([]);        
        const oneCountry = ref("");
        const peruId = "260000";
        const dataShop = ref([]);
        const totalShop = ref({});
        //var jhon = "";        
        const form = reactive({
            selectCountry: "",
            selectDepartment: "",
        });
        
        const loadPlace = async () => {
            /* setea los select de paises y departamentos. return para el return (doble) para que devuelva dataUbigeo.value */
            return await API.get('/api/ubigeo-search-for-public')
            .then(response => {
                dataUbigeo.value = response.data
                return dataUbigeo.value                
                }
            )                        
        }

        const getFilterCountry = () => {
            dataUbigeoCountry.value = dataUbigeo.value.country
            return dataUbigeoCountry.value
        }

        const setSelectCountry = async (data) => {                    
            /* setea el pais, peru o "seleccione un pais" */
            //debugger
            oneCountry.value = isOneCountry(data);           
            form.selectCountry = oneCountry.value ? peruId : "0";            
            if (oneCountry.value) {
                dataUbigeoDepartment.value = getFilterDepartment(peruId)
            }
        }

        const isOneCountry = (data) => {    
            /*Devuelve si hay un solo pais*/
            return data.length == 1
        }

        const onChangeCountry = (event) => {
            /*  */                  
            dataUbigeoDepartment.value =  getFilterDepartment(event.target.value)            
        }

        const getFilterDepartment = (departmentId) => {            
            return dataUbigeo.value.department.filter(e => e.parent == departmentId)
        }

        const onChangeDepartment = (event) => {
            //debugger
            searching.value = true
            getDataShop(event.target.value)
        }

        const getDataShop = (ubigeo) => {
            API.get('/api/shop?ubigeo=' + ubigeo)
            .then(response => {
                dataShop.value = response.data
                console.log(response.data, 'SHOP')
                //getTotalShop();
            })
        }

        const formImagesItem = ref([]);
        const formImagesItemDefault = ref([]);
        const getDefault = () =>{
            API.get("/api/head-global-page-internal").then(response => { 
                formImagesItemDefault.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL +  response.data[0].image;
            })
        }
        const getHeaderDetail = () => {      
            API.get('/api/image-head-page').then(response => {      
                formImagesItem.value = response.data.filter(x=> x.head_menu_navigation_detail===5)[0];
                    console.log('x',formImagesItem.value)

                if (formImagesItem.value!=undefined){
                    formImagesItem.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL + formImagesItem.value.image;
                }
            });
        } 

        const getTotalShop = () => {
            API.get('/api/shop?count')
                .then(response => {
                    totalShop.value = response.data
                    console.log(response.data, 'count')
                })
        }

        return { 
            dataUbigeoCountry, 
            loadPlace, 
            form, 
            oneCountry, 
            setSelectCountry, 
            onChangeCountry, 
            getFilterCountry, 
            dataUbigeoDepartment,
            onChangeDepartment,
            dataShop,
            formImagesItem, formImagesItemDefault, getDefault, getHeaderDetail, totalShop, searching, getTotalShop
        }
    },

    async created(){
        
        await this.loadPlace();
        var data = await this.getFilterCountry();        
        this.setSelectCountry(data);
        
    },

    async mounted() {
        this.getTotalShop()
        this.getDefault()
        this.getHeaderDetail();
    }
}
</script>